export const CustomRoutes = {
    appointmentOverview: '/appointment',
    confirmAccount: '/confirmAccount',
    createTraining: "/createTraining",
    editTraining: "/editTraining",
    createMatch: "/createMatch",
    editMatch: "/editMatch",
    impressum: '/impressum',
    gameLineup: "/gameLineup",
    trainingLineup: "/trainingLineup",
    login: '/login',
    logout: '/logout',
    overview: '/',
    paymentHistory: '/paymenthistory',
    player: '/player',
    privacyPolicy: '/privacyPolicy',
    punishments: '/punishments',
    register: '/register',
    settings: '/settings',
    statistic: '/statistic',
};
