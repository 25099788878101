import React, {useEffect, useState} from "react";
import "../../../../static/css/appointment/lineup/lineup.css";
import {AttendanceStatus} from "../attendance/AttendanceModel";
import {useParams} from "react-router-dom";
import {getAttendanceForAppointment} from "../TrainingHelper";

interface Name {
  id: string;
  text: string;
}

interface DroppedName extends Name {
  x: number;
  y: number;
}

const GameLineup: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const [droppedNames, setDroppedNames] = useState<DroppedName[]>([]);
  const [names, setNames] = useState<Name[]>([]);
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, name: Name, from: "list" | "field") => {
    event.dataTransfer.setData("nameId", name.id);
    event.dataTransfer.setData("source", from);
  };

  useEffect(() => {
    if (id) {
      modifyState(id);
    }
  }, [id]);

  async function modifyState(id: string): Promise<void> {
    try {
      if (id !== null) {


        const attendanceData = await getAttendanceForAppointment(Number(id));
        const confirmedNames = attendanceData
            .filter((att) => att.status === AttendanceStatus.YES || att.status === AttendanceStatus.MAYBE)
            .map((att) => ({ id: att.player.id, text: att.player.name }));
        setNames(confirmedNames);

      }
    } catch (error) {
      console.error("Fehler beim Laden der Trainingsdaten oder Anwesenheit:", error);
    }
  }


  const handleRemoveNameFromList = (nameId: string) => {
    setNames((prevNames) => prevNames.filter((name) => name.id !== nameId));
  };


  const handleDropToList = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const nameId = event.dataTransfer.getData("nameId");
    const draggedName = droppedNames.find((name) => name.id === nameId);

    if (draggedName) {
      setDroppedNames((prev) => prev.filter((name) => name.id !== nameId));
      handleRemoveNameFromList(nameId);
    }
  };

  const handleDropToField = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const nameId = event.dataTransfer.getData("nameId");
    const draggedName = names.find((name) => name.id === nameId);

    if (draggedName) {
      const rect = (event.target as HTMLDivElement).getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      setDroppedNames((prev) => [...prev, { ...draggedName, x, y }]);
      handleRemoveNameFromList(nameId);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
      <div className="drag-and-drop-container">
        <h2>Drag-and-Drop Spielfeld</h2>

        <div className="name-list" onDrop={handleDropToList} onDragOver={handleDragOver}>
          {names.map((name) => (
              <div
                  key={name.id}
                  className="name"
                  draggable
                  onDragStart={(event) => handleDragStart(event, name, "list")}
              >
                {name.text}
              </div>
          ))}
        </div>

        <div className="spielfeld" onDrop={handleDropToField} onDragOver={handleDragOver}>
          {droppedNames.map((name) => (
              <div
                  key={name.id}
                  className="dropped-name"
                  style={{ left: name.x, top: name.y, position: "absolute" }}
              >
                {name.text}
              </div>
          ))}
        </div>
      </div>
  );
};


export default GameLineup;
