import React from "react";
import "../../../../static/css/appointment/training/exercise/group.css"
import {TeamMemberModel} from "../../../member/TeamMemberModel";
import {useDrop} from "react-dnd";
import GroupPlayer from "./GroupPlayer";
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Group: React.FC<{
    group: TeamMemberModel[];
    groupIndex: number;
    onRemovePlayer: (playerId: string, groupIndex?: number) => void;
    onRemoveGroup: (groupIndex: number) => void;
    onDropPlayer: (player: TeamMemberModel, targetGroupIndex: number) => void;
    showOnly: boolean;
}> = ({group, groupIndex, onRemoveGroup, onRemovePlayer, onDropPlayer, showOnly}) => {
    const [{isOver}, dropRef] = useDrop({
        accept: "PLAYER",
        drop: (item: { player: TeamMemberModel }) => {
            onDropPlayer(item.player, groupIndex); // Wenn ein Spieler in diese Gruppe abgelegt wird
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(), // Visuelles Feedback für das Überziehen
        }),
    });


    return (
        <Card className="mb-3 shadow-sm rounded border-0">
            <Card.Header className="d-flex justify-content-between align-items-center bg-light">
                <Card.Title className="mb-0 text-primary" style={{fontSize: '1.25rem'}}>
                    Gruppe {groupIndex + 1}
                </Card.Title>
                {!showOnly && (
                    <button
                        onClick={() => onRemoveGroup(groupIndex)}
                        className="btn btn-outline-danger btn-sm"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        title="Gruppe löschen"
                    >
                        <FontAwesomeIcon icon={faTrash}/>
                    </button>
                )}
            </Card.Header>

            <Card.Body
                ref={dropRef}
                style={{
                    minHeight: '100px',
                    backgroundColor: isOver ? '#e8f5e9' : '#fafafa',
                    border: isOver ? '2px dashed #4caf50' : '2px dashed transparent',
                    borderRadius: '8px',
                    transition: 'background-color 0.3s, border 0.3s',
                }}
                className="d-flex flex-column justify-content-center align-items-center"
            >
                <Row className="" style={{width:'100%'}}>
                    {group.map((player) => (
                        <Col key={player.id} xs={12} md={6} className="mb-2">
                            <GroupPlayer
                                key={player.id}
                                player={player}
                                groupIndex={groupIndex}
                                onRemove={onRemovePlayer}
                                onDrop={onDropPlayer}
                                showOnly={showOnly}
                            />
                        </Col>
                    ))}
                </Row>
                {!showOnly && group.length === 0 && (
                    <p
                        style={{
                            color: '#888',
                            fontStyle: 'italic',
                            fontSize: '0.9rem',
                            marginTop: '10px',
                        }}
                    >
                        Ziehen Sie einen Spieler hierher
                    </p>
                )}
            </Card.Body>
        </Card>

    );
};

export default Group;