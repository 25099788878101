import axios, {AxiosInstance} from "axios";
import {API_URL, FRONTEND_URL} from "../text";
import {TeamMemberModel} from "../components/member/TeamMemberModel";
import {TeamModel} from "../components/TeamModel";
import {PunishmentModel} from "../components/punishment/PunishmentModel";
import {AppointmentModel} from "../components/appointment/training/AppointmentModel";
import {AttendanceModel} from "../components/appointment/training/attendance/AttendanceModel";
import {ExerciseModel, GroupAssignmentModel} from "../components/appointment/training/exercise/ExcerciseModels";


export default class Api {
    private trainings = "/appointments";
    private appointmentsRange = "/appointments/range";
    private match = this.trainings + "/match";
    private attendance = "/attendance";
    private media = "/media";
    private trainingExercise = "/training-exercises";
    private trainingExerciseGroups = "/training-exercise-groups";
    private client: AxiosInstance | undefined;
    private readonly api_url: string;

    constructor() {

        this.api_url = API_URL!;
    }

    init = () => {
        const token = localStorage.getItem('token'); // Token aus dem LocalStorage abrufen

        let headers: Record<string, string> = {
            'Accept': 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
        };

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    handleLogin = (username: string, password: string) => {
        const loginPayload = {
            username: username,
            password: password
        }
        return this.init().post("/authenticate", loginPayload)
    }

    handleRegister = (email: string, username: string, password: string, matchingPassword: string, team: TeamModel) => {
        const registerPayload = {
            hostUrl: FRONTEND_URL + "/confirmAccount",
            email: email,
            username: username,
            password: password,
            matchingPassword: matchingPassword,
            team: team
        }
        return this.init().post("/account/register", JSON.stringify(registerPayload))
    }

    confirmAccount = (token: string) => {
        return this.init().post("/account/register/confirmAccount?token=" + token)
    }

    resendRegisterToken = (email: string, username: string) => {
        const payload = {
            hostUrl: FRONTEND_URL + "/confirmAccount",
            email: email,
            username: username,
        }
        return this.init().post("/account/register/resendRegistrationToken", payload)
    }

    changePassword = (password: string, matchingPassword: string) => {
        const payload = {
            password: password,
            matchingPassword: matchingPassword,
        }
        return this.init().post("/account/changePassword", payload)
    }

    getNewToken = () => {
        return this.init().get("/generateNewToken")
    }

    getUser = () => {
        return this.init().get("/account")
    }

    getTeam = () => {
        return this.init().get("/team")
    }

    getAllTeamMembers = () => {
        return this.init().get("/teamMembers")
    }

    getTeamMembersWithPunishments = () => {
        return this.init().get("/teamMembers/withPersonalPunishments")
    }

    getTeamMembersWithOpenPunishments = () => {
        return this.init().get("/teamMembers/withOpenPersonalPunishments")
    }

    getPersonalPunishments = (teamMemberId: string) => {
        return this.init().get("/personalPunishment/" + teamMemberId)
    }

    getPersonalPunishmentsForTeam = () => {
        return this.init().get("personalPunishment/team")
    }

    addOrUpdatePersonalPunishments = (personalPunishment: string) => {
        return this.init().post("/personalPunishment", personalPunishment)
    }

    deletePersonalPunishments = (personalPunishmentId: string) => {
        return this.init().delete("/personalPunishment/" + personalPunishmentId)
    }

    getPunishments = () => {
        return this.init().get("/punishments")
    }

    deletePunishment = (punishmentId: string) => {
        return this.init().delete("/punishments/" + punishmentId)
    }

    addOrUpdatePunishment = (punishment: PunishmentModel) => {
        return this.init().post("/punishments", punishment)
    }

    addOrUpdateTeamMembers = (params: TeamMemberModel) => {
        return this.init().post("/teamMembers", JSON.stringify(params))
    }

    deleteTeamMembers = (memberId: string) => {
        return this.init().delete("/teamMembers/" + memberId)
    }

    async getAppointments() {
        return this.init().get(this.trainings)
    }

    async getAppointmentsRange(startDate: string, endDate: string) {
        const params = new URLSearchParams({ startDate, endDate });
        return this.init().get(`${this.appointmentsRange}?${params.toString()}`);
    }

    async getAppointmentById(appointmentId: string) {
        return this.init().get(`${this.trainings}/${appointmentId}`)
    }

    async addTraining(trainingData: AppointmentModel) {
        return this.init().post(this.trainings, JSON.stringify(trainingData))
    }

    async addMatch(trainingData: AppointmentModel) {
        return this.init().post(this.match, JSON.stringify(trainingData))
    }

    async deleteAppointmentById(appointmentId: number) {
        return this.init().delete(`${this.trainings}/${appointmentId}`)
    }

    async submitAttendanceForAppointment(appointmentId: number, attendee: AttendanceModel[]) {
        return this.init().post(`${this.attendance}/${appointmentId}`, JSON.stringify(attendee))
    }

    async getAttendanceForAppointment(appointmentId: number) {
        return this.init().get(`${this.attendance}/${appointmentId}`)
    }

    async getAttendancesSumForAppointment(appointmentId: number) {
        return this.init().get(`${this.attendance}/${appointmentId}/sum`)
    }

    async submitTrainingExercise(exercise: ExerciseModel[]) {
        return this.init().post(`${this.trainingExercise}`, JSON.stringify(exercise))
    }

    async getTrainingExercises(trainingExerciseId: number) {
        return this.init().get(`${this.trainingExercise}/training/${trainingExerciseId}`)
    }

    async getTrainingExerciseImage(exerciseId: string): Promise<string> {
        const response = await this.init().get(`${this.media}/blob/${exerciseId}`, { responseType: 'blob' });
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(response.data); // Bild als Base64
        });
    }

    async deleteTrainingExercise(exerciseId: string) {
        return this.init().delete(`${this.trainingExercise}/${exerciseId}`)
    }

    async createOrUpdateGroupAssignments(groups: GroupAssignmentModel[]) {
        return this.init().post(`${this.trainingExerciseGroups}`, JSON.stringify(groups));
    };

    async getGroupAssignments(exerciseId: number) {
        return this.init().get(`${this.trainingExerciseGroups}/${exerciseId}`)
    }

    async deleteGroupForExercise(exerciseId: string) {
        return this.init().delete(`${this.trainingExerciseGroups}/${exerciseId}`)
    }

    async deletePlayerFromExerciseGroup(playerId: string) {
        return this.init().delete(`${this.trainingExerciseGroups}/${playerId}`)
    }

    async getTrainingStatistic() {
        return this.init().get(`${this.attendance}/players/attendance`)
    }
}