import {TeamMemberModel} from "../../../member/TeamMemberModel";
import "../../../../static/css/appointment/training/exercise/groupplayer.css"
import React from "react";
import {ConnectableElement, useDrag, useDrop} from "react-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faX} from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/Col";

type PlayerProps = {
    player: TeamMemberModel;
    groupIndex?: number; // Kann leer sein, wenn der Spieler keiner Gruppe angehört
    onRemove: (playerId: string, groupIndex?: number) => void;
    onDrop: (player: TeamMemberModel, targetGroupIndex: number) => void;
    showOnly: boolean;
};

const GroupPlayer: React.FC<PlayerProps> = ({player, groupIndex, onRemove, onDrop, showOnly}) => {
    const [{isDragging}, dragRef] = useDrag(() => ({
        type: "PLAYER",
        item: {player, groupIndex},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    const [, dropRef] = useDrop({
        accept: "PLAYER",
        drop: (item: { player: TeamMemberModel; groupIndex?: number }) => {
            if (item.groupIndex !== undefined) {
                onDrop(item.player, item.groupIndex); // Wenn ein Spieler fallen gelassen wird
            }
        },
    });


    return (
        <Col className={""}
            key={player.id}
            ref={(node: ConnectableElement | null) => {
                if (!showOnly && node) {
                    dragRef(dropRef(node));
                }
            }}
            style={{
                opacity: isDragging && !showOnly ? 0.5 : 1,
                cursor: showOnly ? "default" : "grab",
                margin: "0px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                padding: "8px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div className="d-flex align-items-center justify-content-between">
        <span
            style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                flex: 1,
                fontSize: "12px",
                fontWeight: "500",
                color: "#333",
                marginRight: "12px",
            }}
            title={player.name} // Tooltip für volle Namen
        >
            {player.name}
        </span>
                {groupIndex !== undefined && !showOnly && (
                    <button
                        onClick={() => onRemove(player.id, groupIndex)}
                        className="btn btn-outline-danger btn-sm"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "24px",
                            height: "24px",
                            padding: "0",
                            borderRadius: "50%",
                        }}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </button>
                )}
            </div>
        </Col>

    );
};

export default GroupPlayer;