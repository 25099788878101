import React from 'react';
import "../../../../static/css/appointment/training/exercise/groupmanager.css"
import {TeamMemberModel} from "../../../member/TeamMemberModel";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Group from "./Group";
import GroupPlayer from "./GroupPlayer";
import Row from "react-bootstrap/Row";
import {t} from 'i18next';
import {Button} from 'react-bootstrap';
import Col from "react-bootstrap/Col";

type Props = {
    players: TeamMemberModel[];
    availablePlayers: TeamMemberModel[];
    exerciseId: string;
    onUpdateGroups: (exerciseId: string, groups: TeamMemberModel[][]) => void;
    onRemovePlayer: (playerId: string, groupIndex?: number) => void;
    onRemoveGroup: (groupIndex: number) => void;
    onDropPlayer: (player: TeamMemberModel, targetGroupIndex: number) => void;
    groups: TeamMemberModel[][];
    setGroups: (groups: TeamMemberModel[][]) => void;
    setAvailablePlayers: (player: TeamMemberModel[]) => void;
    createOneGroup: () => void;
    createGroups: () => void;
    createAndDistributeGroups: () => void;
    distributePlayers: () => void;
    numGroups: number
    setNumGroups: (groupNumber: number) => void;
    handleSave: () => void;
};


const GroupManager: React.FC<Props> = ({
                                           players,
                                           availablePlayers,
                                           exerciseId,
                                           onUpdateGroups,
                                           onRemovePlayer,
                                           onRemoveGroup,
                                           onDropPlayer,
                                           groups,
                                           setGroups,
                                           setAvailablePlayers,
                                           createOneGroup,
                                           createGroups,
                                           createAndDistributeGroups,
                                           distributePlayers,
                                           numGroups,
                                           setNumGroups,
                                           handleSave
                                       }) => {

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="container">
                {availablePlayers.length > 0 && (
                    <div className="not-assigned-section mb-4">
                        <h4 className="text-center text-secondary mb-3">
                            {t("notAssignedPlayer")}
                        </h4>
                        <Row className="g-3">
                            {availablePlayers.map((player) => (
                                <Col key={player.id} xs={12} sm={6}>
                                    <GroupPlayer
                                        player={player}
                                        onRemove={onRemovePlayer}
                                        onDrop={onDropPlayer}
                                        showOnly={false}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}

                <div className="groups-section">
                    <Row className="">
                        {groups.map((group, groupIndex) => (
                            <Col key={groupIndex} xs={12} lg={6}>
                                <Group
                                    group={group}
                                    groupIndex={groupIndex}
                                    onRemovePlayer={onRemovePlayer}
                                    onRemoveGroup={onRemoveGroup}
                                    onDropPlayer={onDropPlayer}
                                    showOnly={false}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>

                <div className="settings-section mt-5">
                    <div className="bg-light p-4 rounded shadow-sm">
                        <h5 className="text-primary mb-4">{t("settings")}</h5>
                        <Row className="align-items-center mb-2">
                            <Col xs={12}>
                                <label htmlFor="numGroups" className="form-label fw-bold">
                                    {t("numberOfGroups")} {/* Übersetzung */}
                                </label>
                                <input
                                    id="numGroups"
                                    type="number"
                                    className="form-control"
                                    value={numGroups}
                                    onChange={(e) => setNumGroups(Number(e.target.value))}
                                    min="1"
                                    placeholder={t("enterGroupNumber")}
                                />
                                <small className="text-muted">{t("groupNumberHint")}</small>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Button variant="outline-primary" onClick={createOneGroup}>
                                    {t("addGroup")}
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="primary" onClick={createGroups}>
                                    {t("createGroups")}
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="info" onClick={createAndDistributeGroups}>
                                    {t("createAndDistribute")}
                                </Button>
                            </Col>
                            <Col>
                                <Button variant="secondary" onClick={distributePlayers}>
                                    {t("distributePlayers")}
                                </Button>
                            </Col>
                        </Row>

                        <div className="additional-actions mt-4">
                            <div className="d-flex flex-wrap">
                                <Button variant="success" onClick={handleSave}>
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DndProvider>

    );
};

export default GroupManager;
