import React from 'react';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {CustomRoutes} from "../../../customRoutes";
import '../../../static/css/appointment/training/createTrainingButton.css';

interface TrainingButtonProps {
    route: string; // Zielroute
    textKey: string; // Übersetzungsschlüssel
}

const TrainingButton: React.FC<TrainingButtonProps> = ({ route, textKey }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Button
            variant={"outline-success"}
            className="create-training-button"
            onClick={() => navigate(route)}
        >
            <FontAwesomeIcon icon={faPlus} className="plus-icon" />
            <span className="button-text">{t(textKey)}</span>
        </Button>
    );
};

export const TrainingOverviewActionButton: React.FC = () => (
    <TrainingButton route={CustomRoutes.createTraining} textKey="training_create" />
);

export const MatchOverviewActionButton: React.FC = () => (
    <TrainingButton route={CustomRoutes.createMatch} textKey="match_create" />
);

export const TrainingOverviewStatisticActionButton: React.FC = () => (
    <TrainingButton route={CustomRoutes.statistic} textKey="view_statistics" />
);
