import '../static/css/home.css';
import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getMembersWithOpenPunishments} from "./member/MemberHelper";
import {
    handlePayPunishment,
    payOrDeletePersonalPunishmentRow,
    renderPayAllRow
} from "./personalPunishment/PersonalPunishmentHelper";
import {getTeam} from "./TeamHelper";
import uuid from "react-uuid";
import {PersonalPunishmentModel} from "./personalPunishment/PersonalPunishmentModel";
import {MemberWithPunishmentModel} from "./member/MemberWithPunishmentModel";
import {TeamModel} from "./TeamModel";
import {Card, FloatingLabel, Form, Modal} from "react-bootstrap";
import overheadKick from "../static/images/fallrueckzieher.svg"
import Button from "react-bootstrap/Button";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const Home = () => {
    const { t } = useTranslation();
    const [members, setMembers] = useState<MemberWithPunishmentModel[]>([])
    const [team, setTeam] = useState<TeamModel>()
    const [punishmentModalShow, setPunishmentModalShow] = useState(false);
    const [teamMemberForModal, setTeamMemberForModal] = useState<MemberWithPunishmentModel>();
    const [search, setSearch] = useState("")
    const compareStrings = (a: string, b: string) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
    const filteredMembers = members.filter(member => {
            return (
                member
                    .name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        }
    ).sort((a, b) => compareStrings(a.name.toLowerCase(), b.name.toLowerCase()));

    const handleClosePunishmentModal = () => setPunishmentModalShow(false);
    const handleShowPunishmentModal = () => setPunishmentModalShow(true);
    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearch(e.target.value !== "" ? e.target.value : "");
        modifyState()
    }

    useEffect(() => {
        modifyState();
    }, []);

    async function modifyState(): Promise<void> {
        let membersWithOpenPunishments = await getMembersWithOpenPunishments();
        setMembers(membersWithOpenPunishments);
        let memberForModal = membersWithOpenPunishments.find(e => e.name == (teamMemberForModal != null ? teamMemberForModal.name : ""));
        if (memberForModal != null) {
            setTeamMemberForModal(memberForModal);
        } else {
            handleClosePunishmentModal();
        }
        let teamModel = await getTeam();
        setTeam(teamModel);
    }

    function copyToClipboard() {
        let textToClipboard = "";
        let overAllAmount = 0;
        members.forEach((member) => {
            textToClipboard = textToClipboard + member.name + "\n"
            let memberAmount = 0;
            member.personalPunishments.forEach((punishment) => {
                textToClipboard = textToClipboard + punishment.name + " " + punishment.amount + "\n"
                overAllAmount = overAllAmount + punishment.amount
                memberAmount = memberAmount + punishment.amount
            })
            textToClipboard += `${t("total_sum")} ${memberAmount}€\n\n`;
        })
        textToClipboard += `${t("overall_total")} ${overAllAmount}€\n`;
        navigator.clipboard.writeText(textToClipboard)
        toast.info(t("punishments_copied_to_clipboard")); // Übersetzung verwenden
    }

    const handleBookPayment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const target = event.target as typeof event.target & {
            paymentDescription: { value: string };
            paymentAmount: { value: string };
        };

        if (target.paymentDescription.value.trim().length !== 0 && target.paymentAmount.value !== "0" && target.paymentAmount.value !== '') {
            const pp = new PersonalPunishmentModel(uuid(), target.paymentDescription.value, parseFloat(target.paymentAmount.value), "", true, "", "", new Date(), new Date());
            await handlePayPunishment([pp]);
            await modifyState();
            toast.success((parseFloat(target.paymentAmount.value) > 0 ? t("payment_income") : t("payment_expense")) + " gebucht");
        } else {
            toast.error(t("fields_required"));
        }
    }

    return (
        <div>
            {team !== null && team !== undefined ?
                (
                    <center>
                        <div>
                            <span className={"club-title"}>{team.teamName}</span>
                            <span className={"club-subtitle"}>{team.teamCash}€</span>
                            <span className={"club-subtitle-1"}>{t("bank_balance")}</span>
                        </div>
                    </center>
                ) : null
            }
            <center>
                <Row>
                    <Col xs={12}>
                        <Button className={""} onClick={() => {
                            copyToClipboard()
                        }}
                                variant="success">{t("copy_punishments_button")}</Button>
                    </Col>
                    <Col xs={12}>
                        <FloatingLabel
                            className={"playerSearchInput"}
                            controlId="floatingInput"
                            label={t("player_search_label")}
                            onChange={handleChangeSearchInput}>
                            <Form.Control type="text" placeholder={t("player_search_placeholder")}/>
                        </FloatingLabel>
                    </Col>
                </Row>
            </center>

            <center>
                <form onSubmit={handleBookPayment}>
                    <Row className="mt-3 align-items-center">
                        <Col xs={12} sm={6}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputBookment"
                                label={t("payment_description_label")}>
                                <Form.Control type="text" name="paymentDescription"
                                              placeholder={t("payment_description_placeholder")}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={3}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputAmount"
                                label={t("payment_amount_label")}>
                                <Form.Control type="text" name="paymentAmount"
                                              placeholder={t("payment_amount_placeholder")}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Button type="submit"
                                    variant={"success"}>{t("book_payment_button")}</Button>
                        </Col>
                    </Row>
                </form>
            </center>

            <center><Row className="mt-3">
                {filteredMembers.map((teamMember) => {
                    return (
                        <Col xs={12} sm={6} md={4} className={"mt-3"} key={teamMember.id.toString()}>
                            <Card>
                                <Card.Img className={"profileCardImage"} variant="top" src={overheadKick}/>
                                <Card.Body>
                                    <Card.Title>
                                        <center>{teamMember.name}</center>
                                    </Card.Title>
                                    <center>
                                        <Button className={""} onClick={() => {
                                            setTeamMemberForModal(teamMember)
                                            handleShowPunishmentModal()
                                        }} variant="success">
                                            {t("punishment_overview_button")}
                                        </Button>
                                    </center>

                                    {renderPayAllRow(teamMember, modifyState)}
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
            </center>


            <Modal scrollable={true} centered show={punishmentModalShow} onHide={handleClosePunishmentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{teamMemberForModal != null ? teamMemberForModal.name : ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={"punishmentModal"}>
                    {payOrDeletePersonalPunishmentRow(teamMemberForModal != null ? teamMemberForModal.personalPunishments : [], modifyState)}
                    {renderPayAllRow(teamMemberForModal, modifyState)}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Home;