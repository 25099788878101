import React from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import AssignAttendanceSummary from "./attendance/AssignAttendanceSummary";
import {AppointmentModel} from './AppointmentModel';
import {CustomRoutes} from "../../../customRoutes";
import {faDumbbell, faEdit, faFutbol, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AppointmentCardProps {
    appointment: AppointmentModel;
    onDelete: (appointmentId: number) => void;
    isMatch?: boolean; // Neue Prop, um zu unterscheiden
}

const AppointmentCard: React.FC<AppointmentCardProps> = ({ appointment, onDelete, isMatch = false }) => {
    const editRoute = isMatch
        ? `${CustomRoutes.editMatch}/${appointment.id}`
        : `${CustomRoutes.editTraining}/${appointment.id}`;

    return (
        <Card className="mb-3">
            <Card.Header className="d-flex justify-content-between align-items-center">
                <FontAwesomeIcon icon={isMatch ? faFutbol : faDumbbell} className="" />
                <span>{new Date(appointment.dateTime).toLocaleString()}</span>
                <div>
                    <Link to={editRoute} className="btn btn-success me-2">
                        <FontAwesomeIcon icon={faEdit} className="" />
                    </Link>
                    <button onClick={() => onDelete(appointment.id)} className="btn btn-danger">
                        <FontAwesomeIcon icon={faTrash} className="" />
                    </button>
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{appointment.name}</Card.Title>
                <Card.Text>{appointment.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <AssignAttendanceSummary appointmentId={appointment.id} />
            </Card.Footer>
        </Card>
    );
};

export default AppointmentCard;
