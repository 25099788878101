import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Col, Container, Row} from 'react-bootstrap';
import ExerciseForm from "../exercise/ExerciseForm";
import ExerciseItem from "../exercise/ExerciseItem";
import {
    deleteTrainingExercise,
    getAppointmentById,
    getAttendanceForAppointment,
    getTrainingExercise,
    submitTrainingExercise
} from "../TrainingHelper";
import {ExerciseModel} from "../exercise/ExcerciseModels";
import {TeamMemberModel} from "../../../member/TeamMemberModel";
import {AttendanceStatus} from "../attendance/AttendanceModel";

const TrainingLineUp = () => {
    const {id} = useParams<{ id?: string }>();
    const [exercises, setExercises] = useState<ExerciseModel[]>([]);
    const [attendance, setAttendance] = useState<TeamMemberModel[]>([]);

    // Lade Daten bei Initialisierung
    useEffect(() => {
        if (id) {
            loadTrainingData(id);
        }
    }, [id]);

    const loadTrainingData = async (id: string): Promise<void> => {
        try {
            // Lade Anwesenheits- und Übungsdaten
            const [trainingData, attendanceData, exerciseData] = await Promise.all([
                getAppointmentById(id),
                getAttendanceForAppointment(Number(id)),
                getTrainingExercise(Number(id))
            ]);

            if (trainingData && attendanceData) {
                const attendingPlayers = attendanceData
                    .filter(att => att.status === AttendanceStatus.YES)
                    .map(att => att.player);
                setAttendance(attendingPlayers);
            }

            setExercises(exerciseData);
        } catch (error) {
            console.error("Fehler beim Laden der Trainingsdaten:", error);
        }
    };

    const addExercise = async (exercise: Omit<ExerciseModel, 'id' | 'groups'>) => {
        const newExercise = {
            ...exercise,
            id: Date.now().toString(),
            appointmentId: id,
            groups: []
        };

        const updatedExercises = [...exercises, newExercise];
        setExercises(updatedExercises);
        await submitTrainingExercise(updatedExercises);
    };

    // Gruppen aktualisieren
    const updateGroups = (exerciseId: string, groups: TeamMemberModel[][]) => {
        setExercises(prevExercises =>
            prevExercises.map(exercise =>
                exercise.id === exerciseId ? {...exercise, groups} : exercise
            )
        );
    };

    // Übung löschen
    const handleDeleteExercise = async (exerciseId: string) => {
        try {
            await deleteTrainingExercise(exerciseId);
            setExercises(exercises.filter(ex => ex.id !== exerciseId));
        } catch (error) {
            console.error("Fehler beim Löschen der Übung:", error);
        }
    };

    return (
        <Container className="mt-4">
            <h1 className="text-center mb-4">Trainingsübungen</h1>

            {/* Übungsformular */}
            <center>
                <Row className="mb-4" style={{width: "50%"}}>
                    <Col xs={12}>
                        <ExerciseForm onAddExercise={addExercise}/>
                    </Col>
                </Row>
            </center>
            {/* Übungsliste */}
            <div className={""}>
                <Row className="mb-4">
                    {exercises.map(exercise => (
                        <Col key={exercise.id} xs={12}>
                            <ExerciseItem
                                exercise={exercise}
                                players={attendance}
                                onUpdateGroups={updateGroups}
                                onDeleteExercise={handleDeleteExercise}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    );
};

export default TrainingLineUp;