import {TeamMemberModel} from "../../../member/TeamMemberModel";

export class AttendancePlayerStatisticModel {
    constructor(
        public playerId: number,
        public playerName: string,
        public attendance: Record<string, string>,
        public yesPercentage: number,
    ) {
        this.playerId = playerId;
        this.playerName = playerName;
        this.attendance = attendance;
        this.yesPercentage = yesPercentage;
    }
}
