import React, {useState} from "react";
import {API_URL} from "../../text";
import Api from "../../helper/Api";
import {toast} from "react-toastify";

const ChangePasswordForm = () => {
    const [password, setPassword] = useState('');
    const [matchingPassword, setMatchingPassword] = useState('');

    const handleChangeMatchingPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMatchingPassword(e.target.value);
    };

    const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const handeChangePassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let api = new Api();
        api.changePassword(password, matchingPassword)
            .then(() => {
                    toast.success("Password geändert");
                }
            )
            .catch(() => {
                toast.error("Password nicht geändet")
            })
    }

    return (
        <div>
            <form>
                <div className="login-row">
                    <label>Neues Passwort</label>
                    <input type={"password"} onChange={handleChangePassword} placeholder={"Passwort eingeben"}
                           name="password" autoComplete={"new-password"}/>
                </div>
                <div className="login-row">
                    <label>Neues Passwort nochmal</label>
                    <input type={"password"} onChange={handleChangeMatchingPassword}
                           placeholder={"Passwort wiederholen"}
                           name="matchingPassword" autoComplete={"new-matching-password"}/>
                </div>
                <div id="login-button" className="login-row">
                    <button className={"btn btn-success"}
                            onClick={handeChangePassword}>{API_URL!.includes("localhost") ? "LOCAL-NeuesPasswort" : "Passwort ändern"}</button>
                </div>
            </form>
        </div>
    )
}

export default ChangePasswordForm;