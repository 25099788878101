export class AttendanceSumModel {
    constructor(
        public attendanceSumYes: number,
        public attendanceSumNo: number,
        public attendanceSumMaybe: number
    ) {
        this.attendanceSumYes = attendanceSumYes;
        this.attendanceSumNo = attendanceSumNo;
        this.attendanceSumMaybe = attendanceSumMaybe;
    }
}
