import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";

export class MemberWithPunishmentModel {
    constructor(public id: string, public name: string, public teamId: string, public roleIds: [], public personalPunishments: PersonalPunishmentModel[]) {
        this.id = id;
        this.name = name;
        this.teamId = teamId;
        this.roleIds = roleIds;
        this.personalPunishments = personalPunishments;
    }
}