import React, {useEffect, useState} from "react";
import Api from "../../helper/Api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import uuid from 'react-uuid';
import {TeamMemberModel} from "./TeamMemberModel";
import {Card, Dropdown, FloatingLabel, Form} from "react-bootstrap";
import {getMembersWithPunishments} from "./MemberHelper";
import {getPunishments} from "../punishment/PunishmentHelper";
import Button from "react-bootstrap/Button";
import {MemberWithPunishmentModel} from "./MemberWithPunishmentModel";
import {PunishmentModel} from "../punishment/PunishmentModel";
import {PersonalPunishmentModel} from "../personalPunishment/PersonalPunishmentModel";
import overheadKick from "../../static/images/fallrueckzieher.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";

const MemberPage = () => {
    const { t } = useTranslation();
    const [teamMembers, setTeamMembers] = useState<MemberWithPunishmentModel[]>([]);
    const [punishments, setPunishments] = useState<PunishmentModel[]>([]);
    const [memberToPunish, setMemberToPunish] = useState<MemberWithPunishmentModel>();
    const [punishmentForChosenMember, setPunishmentForChosenMember] = useState<PunishmentModel>();
    const [search, setSearch] = useState("")

    const filteredMembers = teamMembers.filter(member => {
            return (
                member
                    .name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        }
    );

    useEffect(() => {
        modifyState();
    }, []);
    const compareStrings = (a: string, b: string) => {
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }

    async function modifyState(): Promise<void> {
        let memberWithPunishmentModels: MemberWithPunishmentModel[] = await getMembersWithPunishments();
        memberWithPunishmentModels.sort((a, b) => compareStrings(a.name.toLowerCase(), b.name.toLowerCase()));
        setTeamMembers(memberWithPunishmentModels);
        setPunishments(await getPunishments());
    }

    const handleAddMember = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const api = new Api();
        const target = event.target as typeof event.target & {
            uuid: { value: string };
            memberName: { value: string };
        };
        if (target.memberName.value === "") {
            toast.error(t("nameNotEmpty"));
        } else {
            await api.addOrUpdateTeamMembers(new TeamMemberModel(target.uuid.value, target.memberName.value, null))
                .then(() => {
                    modifyState();
                    toast.success(target.memberName.value + " " + t("added"));
                })
                .catch(() => {
                    toast.error(t("nameNotEmpty"));
                });
        }
    }

    async function handleDeleteMember(teamMember: MemberWithPunishmentModel): Promise<void> {
        const api = new Api();
        await api.deleteTeamMembers(teamMember.id)
            .then(() => {
                modifyState();
                toast.success(teamMember.name + " " + t("deleted"));
            })
            .catch((err) => {
                toast.error(err.response.data);
            });
    }

    function handleOnChangeMember(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let memberWithPunishment = teamMembers.find(teamMember => teamMember.name.trim() === event.target.value);
        if (memberWithPunishment !== undefined) {
            setMemberToPunish(memberWithPunishment)
        }
    }

    function handleOnChangePunishment(event: React.ChangeEvent<HTMLSelectElement>) {
        event.preventDefault();
        let punishment = punishments.find(punishment => punishment.name.trim() === event.target.value);
        if (punishment !== undefined) {
            setPunishmentForChosenMember(punishment)
        }
    }

    async function handleAddPunishmentToMember(punishment: PunishmentModel | undefined, teamMember: MemberWithPunishmentModel | undefined) {
        const api = new Api();
        if (punishment != undefined && teamMember !== undefined) {
            let personalPunishment = new PersonalPunishmentModel(uuid(), punishment.name, punishment.amount, teamMember.teamId, false, teamMember.id, teamMember.name, new Date, new Date());
            await api.addOrUpdatePersonalPunishments(JSON.stringify([personalPunishment]));
            await modifyState();
            toast.success((t("punishment")) + " " + t("for") + " "+ teamMember.name + " "+ t("added"));
        } else {
            toast.error(t("fieldsNotEmpty"));
        }
    }

    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
        };
        setSearch(e.target.value !== "" ? e.target.value : "");
        modifyState()
    }

    return (
        <div>
            <center>
                <Row>
                    <Col xs={12}>
                        <FloatingLabel
                            className={"playerSearchInput"}
                            controlId="floatingInput"
                            label={t("player_search_label")}
                            onChange={handleChangeSearchInput}>
                            <Form.Control type="text" placeholder={t("player_search_placeholder")}/>
                        </FloatingLabel>
                    </Col>
                </Row>
            </center>
            <center>
                <form onSubmit={handleAddMember}>
                    <input type="hidden" name="uuid" value={uuid()}/>
                    <Row className="mt-3 align-items-center">
                        <Col xs={9}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputPunishment"
                                label={t("name_placeholder")}>
                                <Form.Control type="text" name="memberName"
                                              placeholder={t("name_placeholder")}/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={3}>
                            <Button type="submit"
                                    variant={"success"}>{t("player_create")}</Button>
                        </Col>
                    </Row>
                </form>
            </center>
            <center>
                <Row className="mt-3">
                    <Col xs={12} sm={6}>
                        <select className="form-control" name="member" onChange={handleOnChangeMember}>
                            <option value="DEFAULT">{t("player_select")}</option>
                            {teamMembers.map((teamMember) => {
                                return (
                                    <option key={teamMember.id.toString()}>
                                        {teamMember.name}
                                    </option>);
                            })
                            }
                        </select>
                    </Col>
                    <Col xs={12} sm={3}>
                        <select className="form-control" name="punishment" onChange={handleOnChangePunishment}>
                            <option value="DEFAULT">{t("punishment_choose")}</option>
                            {punishments.map((punishment) => {
                                return (
                                    <option key={punishment.punishmentId.toString()}>
                                        {punishment.name}
                                    </option>);
                            })
                            }
                        </select>
                    </Col>
                    <Col xs={12} sm={3}>
                        <Button
                            onClick={() => handleAddPunishmentToMember(punishmentForChosenMember, memberToPunish)}
                            variant="success">{t("punishment_assign")}</Button>

                    </Col>
                </Row>
            </center>
            <center>
                <Row className="mt-3">
                    {filteredMembers.map((teamMember) => {
                        return (
                            <Col xs={12} sm={12} md={6} className={"mt-3"} key={teamMember.id.toString()}>
                                <Card>
                                    <div className={"deleteButton"}>
                                        <Button
                                            onClick={() => {
                                                handleDeleteMember(teamMember)
                                            }}
                                            variant="danger"><FontAwesomeIcon icon={faTrash}/></Button>
                                    </div>
                                    <Card.Img className={"profileCardImage"} variant="top" src={overheadKick}/>
                                    <Card.Body>
                                        <Card.Title>
                                            <center>{teamMember.name}</center>
                                        </Card.Title>
                                        <center>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    {t("punishment_add")}
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {punishments.map((punishment) => {
                                                        return (
                                                            <Dropdown.Item key={punishment.punishmentId.toString()}
                                                                           onClick={() => handleAddPunishmentToMember(punishment, teamMember)}>
                                                                {punishment.name}
                                                            </Dropdown.Item>);
                                                    })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </center>
                                        <div className="separator">{t("player_edit")}</div>
                                        <Row>
                                            <form onSubmit={handleAddMember}>
                                                <input type="hidden" name="uuid" value={teamMember.id}/>
                                                <Col xs={12}>
                                                    <FloatingLabel
                                                        className={""}
                                                        controlId="floatingInputPunishment"
                                                        label={t("name_placeholder")}>
                                                        <Form.Control type="text" name="memberName"
                                                                      placeholder={t("name_placeholder")}/>
                                                    </FloatingLabel>
                                                </Col>
                                                <Col xs={12}>
                                                    <div><Button style={{width: "100%"}} type="submit"
                                                                 variant={"success"}>{t("edit")}</Button></div>
                                                </Col>
                                            </form>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </center>
        </div>
    )
}

export default MemberPage;

