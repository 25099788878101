import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AuthContext} from "./AuthContextProvider";
import {CustomRoutes} from "../../customRoutes";

const Logout = () => {
    const { logout} = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const handleLogout = () => {
        logout();
    };

    useEffect(() => {
        handleLogout();
        setTimeout(() => {
            navigate(CustomRoutes.overview, {replace: true, state: {from: location}});
        }, 0);
    }, [location]);

    return null;
};

export default Logout;