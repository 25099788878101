import '../../static/css/punishment.css';

import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Api from "../../helper/Api";
import {PunishmentModel} from "./PunishmentModel";
import uuid from "react-uuid";
import Button from "react-bootstrap/Button";
import {getPunishments} from "./PunishmentHelper";
import {Card, FloatingLabel, Form} from "react-bootstrap";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import whistle from "../../static/images/whistle.svg";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PunishmentPage = () => {
    const [punishments, setPunishments] = useState<PunishmentModel[]>([]);
    const [search, setSearch] = useState("")

    const filteredPunishments = punishments.filter(punishment => {
            return (
                punishment
                    .name
                    .toLowerCase()
                    .includes(search.toLowerCase())
            );
        }
    );

    useEffect(() => {
        modifyState();
    }, []);

    async function modifyState(): Promise<void> {
        let punishmentModels = await getPunishments();
        setPunishments(punishmentModels);
    }

    const handleAddPunishment = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const api = new Api();
        const target = event.target as typeof event.target & {
            uuid: { value: string };
            punishmentName: { value: string };
            punishmentAmount: { value: number };
        };

        if (target.punishmentName.value === "") {
            toast.error("Name darf nicht leer sein");
        } else {
            await api.addOrUpdatePunishment(
                new PunishmentModel(target.uuid.value, target.punishmentName.value, target.punishmentAmount.value)
            ).then(() => {
                modifyState();
                toast.success("Strafe geändert oder hinzugefügt");
            })
                .catch(() => {
                    toast.error("Name darf nicht leer sein");
                });
        }
    }
    const handleDeletePunishment = async (punishment: PunishmentModel) => {
        const api = new Api();
        await api.deletePunishment(punishment.punishmentId);
        await modifyState();
        toast.success("Strafe gelöscht");
    }
    const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.target as typeof e.target & {
            name: { value: string };
        };
        setSearch(e.target.value !== "" ? e.target.value : "");
        modifyState()
    }

    const copyPunishmentsToClipboard = () => {
        let textToClipboard = "";

        punishments.forEach((punishment) => {
            textToClipboard = textToClipboard + punishment.name + " " + punishment.amount + "€ \n"
        })

        navigator.clipboard.writeText(textToClipboard)
        toast.info("Strafenkatalog in Zwischenablage kopiert");
    }

    return (
        <div>
            <center>
                <Row>
                    <Col xs={12}>
                        <Button className={""} onClick={() => {
                            copyPunishmentsToClipboard()
                        }}
                                variant="success">Strafenkatalog kopieren</Button>
                    </Col>
                    <Col xs={12}>
                        <FloatingLabel
                            className={"playerSearchInput"}
                            controlId="floatingInput"
                            label="Strafensuche"
                            onChange={handleChangeSearchInput}>
                            <Form.Control type="text" placeholder="Strafensuche"/>
                        </FloatingLabel>
                    </Col>
                </Row>
            </center>

            <center>
                <form onSubmit={handleAddPunishment}>
                    <input type="hidden" name="uuid" value={uuid()}/>
                    <Row className={"mt-2 align-items-center"}>
                        <Col xs={12} sm={6}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputPunishment"
                                label="Neue Strafe">
                                <Form.Control type="text" name="punishmentName" placeholder="Neue Strafe"/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={3}>
                            <FloatingLabel
                                className={""}
                                controlId="floatingInputAmount"
                                label="Betrag">
                                <Form.Control type="text" name="punishmentAmount" placeholder="Betrag"/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Button type="submit" variant={"success"}>Neue Strafe</Button>
                        </Col>
                    </Row>
                </form>
            </center>

            <center>
                <Row>
                    {filteredPunishments.map((punishment) => {
                        return (
                            <Col xs={12} sm={12} md={6} className={"mt-3"} key={punishment.punishmentId.toString()}>
                                <Card>
                                    <div className={"deleteButton"}>
                                        <Button
                                            onClick={() =>
                                                handleDeletePunishment(punishment)
                                            }
                                            variant="danger"><FontAwesomeIcon icon={faTrash}/>
                                        </Button>
                                    </div>
                                    <Card.Img className={"profileCardImage"} variant="top" src={whistle}/>
                                    <Card.Body>
                                        <Card.Title>
                                            <span>{punishment.name} - {punishment.amount}€</span>
                                        </Card.Title>
                                        <center>
                                            <div className="separator">Strafe bearbeiten</div>
                                            <Row>

                                                <form onSubmit={handleAddPunishment}>
                                                    <input type="hidden" name="uuid" value={punishment.punishmentId}/>
                                                    <Col xs={12}>
                                                        <FloatingLabel
                                                            className={""}
                                                            controlId="floatingInputPunishment"
                                                            label="Strafe">
                                                            <Form.Control type="text" name="punishmentName"
                                                                          placeholder="Strafe"/>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <FloatingLabel
                                                            className={""}
                                                            controlId="floatingInputAmount"
                                                            label="Betrag">
                                                            <Form.Control type="text" name="punishmentAmount"
                                                                          placeholder="Betrag"/>
                                                        </FloatingLabel>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <div><Button style={{width: "100%"}} type="submit"
                                                                     variant={"success"}>Bearbeiten</Button></div>
                                                    </Col>
                                                </form>
                                            </Row>
                                        </center>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
                </Row>
            </center>
        </div>
    )
}

export default PunishmentPage;