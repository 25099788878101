import {TeamMemberModel} from "../../../member/TeamMemberModel";

export class ExerciseModel {
    constructor(
        public id: string,
        public imagePath?: string,
        public base64ImageLocal?: string,
        public name?: string,
        public description?: string,
        public trainingId?: string,
        public groups?: TeamMemberModel[][],
        public publicView?: boolean,
    ) {
        this.id = id;
        this.imagePath = imagePath;
        this.base64ImageLocal = base64ImageLocal;
        this.name = name;
        this.description = description;
        this.trainingId = trainingId;
        this.groups = groups;
        this.publicView = publicView;
    }
}

export class GroupAssignmentModel {
    constructor(
        public id: string,
        public exerciseId: string,
        public trainingId: string,
        public players: TeamMemberModel[]
    ) {
        this.id = id;
        this.exerciseId = exerciseId;
        this.trainingId = trainingId;
        this.players = players
    }

}