import React, {useEffect, useState} from "react";
import {getAttendanceStatistic} from "./training/TrainingHelper";
import {AttendancePlayerStatisticModel} from "./training/attendance/AttendancePlayerStatisticModel";

const AttendanceStatistic: React.FC = () => {
    const [data, setData] = useState<AttendancePlayerStatisticModel[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [trainings, setTrainings] = useState<string[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: "asc" | "desc" } | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const attendanceStatistics: AttendancePlayerStatisticModel[] = await getAttendanceStatistic();

                const uniqueTrainings = Array.from(
                    new Set(attendanceStatistics.flatMap((player) => Object.keys(player.attendance)))
                );

                setTrainings(uniqueTrainings);
                setData(attendanceStatistics);
            } catch (err) {
                setError("Fehler beim Laden der Daten");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const sortData = (key: string) => {
        let direction: "asc" | "desc" = "asc";
        if (sortConfig?.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (key === "yesPercentage") {
                return direction === "asc"
                    ? a.yesPercentage - b.yesPercentage
                    : b.yesPercentage - a.yesPercentage;
            }
            if (key === "playerName") {
                return direction === "asc"
                    ? a.playerName.localeCompare(b.playerName)
                    : b.playerName.localeCompare(a.playerName);
            }
            // For dynamic training columns
            if (trainings.includes(key)) {
                const aValue = a.attendance[key] || "";
                const bValue = b.attendance[key] || "";
                return direction === "asc"
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }
            return 0;
        });
        setData(sortedData);
    };

    if (loading) return <p>Lade Daten...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>Anwesenheitsstatistik</h2>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                <tr>
                    <th onClick={() => sortData("playerName")} style={{ cursor: "pointer" }}>
                        Spieler {sortConfig?.key === "playerName" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    <th onClick={() => sortData("yesPercentage")} style={{ cursor: "pointer" }}>
                        Anwesenheit in % {sortConfig?.key === "yesPercentage" && (sortConfig.direction === "asc" ? "↑" : "↓")}
                    </th>
                    {trainings.map((training) => (
                        <th key={training} onClick={() => sortData(training)} style={{ cursor: "pointer" }}>
                            {training} {sortConfig?.key === training && (sortConfig.direction === "asc" ? "↑" : "↓")}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((player) => (
                    <tr key={player.playerId}>
                        <td>{player.playerName}</td>
                        <td>{player.yesPercentage.toFixed(2)}</td>
                        {trainings.map((training) => (
                            <td key={training}>
                                {player.attendance[training] || "UNKNOWN"} {/* Fallback für fehlende Einträge */}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default AttendanceStatistic;
