import React from "react";
import {useTranslation} from "react-i18next";
import '../../static/css/footer.css'

const Footer = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const isDevelopment = process.env.NODE_ENV === 'development';

    return (
        <footer className="footer-nav navbar bottom">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <div className="col-md-6 mb-md-0 mb-3">
                        <a href="/impressum">Impressum</a>
                    </div>

                    <div className="col-md-6 mb-md-0 mb-3">
                        <a href="/privacyPolicy">Datenschutz</a>
                    </div>
                </div>
            </div>
            {isDevelopment && (
                <div className="col-md-4 mb-md-0 mb-3">
                    <div className="language-switcher">
                        <button onClick={() => handleLanguageChange('de')} className="btn btn-link">
                            Deutsch
                        </button>
                        <button onClick={() => handleLanguageChange('en')} className="btn btn-link">
                            English
                        </button>
                    </div>
                </div>
            )}
        </footer>
    );
};

export default Footer;
