import {TeamMemberModel} from "../../../member/TeamMemberModel";

export enum AttendanceStatus {
    YES = "YES",
    NO = "NO",
    MAYBE = "MAYBE",
}

export class AttendanceModel {
    constructor(
        public player: TeamMemberModel,
        public status: AttendanceStatus,
        public reason: string
    ) {
        this.player = player;
        this.status = status;
        this.reason = reason;
    }
}
