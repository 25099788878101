import React, {useEffect, useState} from 'react';
import Api from "../../../../helper/Api";
import {AttendanceSumModel} from "./AttendanceSumModel";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestion, faThumbsDown, faThumbsUp} from '@fortawesome/free-solid-svg-icons';
import "../../../../static/css/appointment/training/attendance/assignAttendanceSummary.css"

interface AssignAttendanceSummaryProps {
    appointmentId: number;
}

const AssignAttendanceSummary: React.FC<AssignAttendanceSummaryProps> = ({ appointmentId }) => {
    const [attendanceSummary, setAttendanceSummary] = useState<AttendanceSumModel | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadAttendanceSummary();
    }, [appointmentId]);

    async function loadAttendanceSummary() {
        try {
            const res = await new Api().getAttendancesSumForAppointment(appointmentId);
            setAttendanceSummary(new AttendanceSumModel(
                res.data.attendanceSumYes,
                res.data.attendanceSumNo,
                res.data.attendanceSumMaybe
            ));
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <div>Loading...</div>;

    return (
        <div className="attendance-summary-bar">
            <div className="attendance-item">
                <FontAwesomeIcon icon={faThumbsUp} className="attendance-icon thumbs-up me-2" />
                <span>{attendanceSummary?.attendanceSumYes}</span>
            </div>
            <div className="attendance-item">
                <FontAwesomeIcon icon={faQuestion} className="attendance-icon question me-2" />
                <span>{attendanceSummary?.attendanceSumMaybe}</span>
            </div>
            <div className="attendance-item">
                <FontAwesomeIcon icon={faThumbsDown} className="attendance-icon thumbs-down me-2" />
                <span>{attendanceSummary?.attendanceSumNo}</span>
            </div>
        </div>
    );
};

export default AssignAttendanceSummary;
