import React, {useCallback, useEffect, useState} from 'react';
import {AppointmentModel} from './training/AppointmentModel';
import {deleteAppointmentById, getAppointmentsRange} from "./training/TrainingHelper";
import {toast} from "react-toastify";
import '../../static/css/appointment/appointmentOverview.css';
import {useTranslation} from "react-i18next";
import {
    MatchOverviewActionButton,
    TrainingOverviewActionButton,
    TrainingOverviewStatisticActionButton
} from "./training/TrainingOverviewActionButtons";
import {Container} from 'react-bootstrap';
import AppointmentCard from "./training/AppointmentCard";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Hilfsfunktionen, um das Start- und Enddatum zu berechnen
const getDefaultStartDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = today.getDate() - dayOfWeek; // Erster Tag der Woche (Sonntag)
    today.setDate(diff - 7); // Eine Woche zurück
    return today.toISOString(); // ISO-Format für das Datum
};

const getDefaultEndDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = today.getDate() - dayOfWeek; // Erster Tag der Woche (Sonntag)
    today.setDate(diff + 7); // Eine Woche vorwärts
    return today.toISOString(); // ISO-Format für das Datum
};

const AppointmentOverview: React.FC = () => {
    const { t } = useTranslation();
    const [appointmentModels, setAppointmentModels] = useState<AppointmentModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterType, setFilterType] = useState<string>("ALL");
    const [startDate, setStartDate] = useState(getDefaultStartDate);  // Hier berechnetes Startdatum
    const [endDate, setEndDate] = useState(getDefaultEndDate); // Hier berechnetes Enddatum
    const [isAppending, setIsAppending] = useState(false);

    const loadAppointments = useCallback(async (start: string, end: string) => {
        setIsLoading(true);
        try {
            const newAppointments = await getAppointmentsRange(start, end);

            // Verhindern, dass doppelte Termine hinzugefügt werden
            setAppointmentModels(prevAppointments => {
                const existingIds = new Set(prevAppointments.map(a => a.id));
                const uniqueAppointments = newAppointments.filter(a => !existingIds.has(a.id));

                return [...prevAppointments, ...uniqueAppointments].sort((a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime());
            });
        } catch {
            toast.error(t("error_load_training_list"));
        } finally {
            setIsLoading(false);
            setIsAppending(false); // Anhängen abgeschlossen
        }
    }, [t]);

    const handleDelete = async (appointmentId: number) => {
        if (window.confirm(t("training_delete_check"))) {
            try {
                await deleteAppointmentById(appointmentId);
                setAppointmentModels(prevAppointments =>
                    prevAppointments.filter(appointment => appointment.id !== appointmentId)
                );
                toast.success(t("training_deleted"));
            } catch {
                toast.error(t("training_delete_error"));
            }
        }
    };

    const loadOlderAppointments = () => {
        const newStartDate = new Date(new Date(startDate).getTime() - 7 * 24 * 60 * 60 * 1000); // 1 Woche zurück
        setStartDate(newStartDate.toISOString());
        setIsAppending(true); // Setze den Zustand auf "Anhängen", wenn neue Termine geladen werden
    };

    const loadNewerAppointments = () => {
        const newEndDate = new Date(new Date(endDate).getTime() + 7 * 24 * 60 * 60 * 1000); // 1 Woche vorwärts
        setEndDate(newEndDate.toISOString());
        setIsAppending(true); // Setze den Zustand auf "Anhängen", wenn neue Termine geladen werden
    };

    useEffect(() => {
        loadAppointments(startDate, endDate);
    }, [startDate, endDate, loadAppointments]);

    const filteredAppointments = appointmentModels.filter(appointment => {
        if (filterType === "ALL") return true;
        return appointment.appointmentType === filterType;
    });

    return (
        <div>
            <h2 className={"d-flex justify-content-center mb-3"}>{t("appointment_overview")}</h2>
            <div className="d-flex justify-content-center mb-3">
                <TrainingOverviewActionButton />
                <MatchOverviewActionButton />
                <TrainingOverviewStatisticActionButton />
            </div>

            {/* Umschalter */}
            <div className="d-flex justify-content-center mb-3">
                <button
                    className={`btn ${filterType === "ALL" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => setFilterType("ALL")}
                >
                    {t("show_all")}
                </button>
                <button
                    className={`btn ${filterType === "MATCH" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => setFilterType("MATCH")}
                >
                    {t("show_matches")}
                </button>
                <button
                    className={`btn ${filterType === "TRAINING" ? "btn-primary" : "btn-outline-primary"} mx-1`}
                    onClick={() => setFilterType("TRAINING")}
                >
                    {t("show_trainings")}
                </button>
            </div>

            {/* Neue Buttons für ältere und neuere Termine */}
            <div className="d-flex justify-content-center mb-3">
                <button className="btn btn-secondary mx-1" onClick={loadOlderAppointments}>
                    {t("load_older_appointments")}
                </button>
                <button className="btn btn-secondary mx-1" onClick={loadNewerAppointments}>
                    {t("load_newer_appointments")}
                </button>
            </div>

            <Container>
                {isLoading && !isAppending ? (
                    <div className="loader"></div>
                ) : (
                    filteredAppointments.length === 0 ? (
                        <p>{t("no_appointments_found")}</p>
                    ) : (
                        <Row className="training-list">
                            {filteredAppointments.map(appointment => (
                                <Col key={appointment.id} xs={12} md={6} className="mb-4">
                                    <AppointmentCard
                                        appointment={appointment}
                                        onDelete={handleDelete}
                                        isMatch={appointment.appointmentType === "MATCH"}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )
                )}
                {isAppending && (
                    <div className="loader" style={{ textAlign: 'center', marginTop: '20px' }}>
                        {t("loading_more_appointments")}
                    </div>
                )}
            </Container>
        </div>
    );
};

export default AppointmentOverview;
