import React, { useState } from "react";
import { AttendanceStatus } from "./AttendanceModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import "../../../../static/css/appointment/training/attendance/assignAttendanceButtons.css";

interface AttendanceButtonsProps {
    memberId: string;
    initialStatus: AttendanceStatus;
    onStatusChange: (status: AttendanceStatus) => void;
}

const AttendanceButtons: React.FC<AttendanceButtonsProps> = ({
                                                                 memberId,
                                                                 initialStatus,
                                                                 onStatusChange,
                                                             }) => {
    const [status, setStatus] = useState<AttendanceStatus>(initialStatus);

    const handleButtonClick = (newStatus: AttendanceStatus) => {
        setStatus(newStatus);
        onStatusChange(newStatus);
    };

    return (
        <div className="attendance-buttons">
            <button
                className={`btn attendance-btn ${status === AttendanceStatus.YES ? "active yes" : ""}`}
                onClick={() => handleButtonClick(AttendanceStatus.YES)}
                aria-label="Anwesend"
            >
                <FontAwesomeIcon icon={faThumbsUp} />
            </button>
            <button
                className={`btn attendance-btn ${status === AttendanceStatus.MAYBE ? "active maybe" : ""}`}
                onClick={() => handleButtonClick(AttendanceStatus.MAYBE)}
                aria-label="Vielleicht"
            >
                <FontAwesomeIcon icon={faQuestion} />
            </button>
            <button
                className={`btn attendance-btn ${status === AttendanceStatus.NO ? "active no" : ""}`}
                onClick={() => handleButtonClick(AttendanceStatus.NO)}
                aria-label="Abwesend"
            >
                <FontAwesomeIcon icon={faThumbsDown} />
            </button>
        </div>
    );
};

export default AttendanceButtons;
