import React, {useContext} from 'react';
import './static/css/App.css';
import './static/css/overrideBootstrap.css';

import {Route, Routes, useNavigate} from 'react-router-dom';
import {AuthContext} from './components/auth/AuthContextProvider';
import Home from "./components/Home";
import MemberPage from "./components/member/MemberPage";
import PunishmentPage from "./components/punishment/PunishmentPage";
import PaymentHistory from "./components/PaymentHistory";
import SettingsPage from "./components/settings/SettingsPage";
import PrivacyPolicy from "./components/footer/PrivacyPolicy";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import LoginForm from "./components/auth/LoginForm";
import Logout from "./components/auth/Logout";
import RegisterForm from "./components/auth/RegisterForm";
import ConfirmAccountForm from "./components/auth/ConfirmAccountForm";
import ResAppBar from "./components/ResAppBar";
import Footer from "./components/footer/Footer";
import AppointmentOverview from "./components/appointment/AppointmentOverview";
import TrainingForm from "./components/appointment/training/TrainingForm";
import {ToastContainer} from 'react-toastify';
import Impressum from "./components/footer/Impressum";
import './i18n';
import {CustomRoutes} from "./customRoutes"
import GameLineup from "./components/appointment/training/lineup/GameLineup";
import TrainingLineup from "./components/appointment/training/lineup/TrainingLineup";
import AttendanceStatistic from "./components/appointment/AttendanceStatistic";

const App: React.FC = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    if (isLoggedIn && window.location.pathname === '/login') {
        navigate(CustomRoutes.overview);
    }

    return (
        <div className="App">
            <ToastContainer />
            {isLoggedIn !== null && isLoggedIn !== undefined && isLoggedIn && <ResAppBar />}

            <div className="main-content">
                <Routes>
                    <Route path={CustomRoutes.overview} element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path={CustomRoutes.player} element={<ProtectedRoute><MemberPage /></ProtectedRoute>} />
                    <Route path={CustomRoutes.appointmentOverview} element={<ProtectedRoute><AppointmentOverview /></ProtectedRoute>} />
                    <Route path={CustomRoutes.editTraining + "/:id"} element={<ProtectedRoute><TrainingForm isMatch={false}/></ProtectedRoute>} />
                    <Route path={CustomRoutes.editMatch + "/:id"} element={<ProtectedRoute><TrainingForm isMatch={true}/></ProtectedRoute>} />
                    <Route path={CustomRoutes.createTraining} element={<ProtectedRoute><TrainingForm isMatch={false} /></ProtectedRoute>} />
                    <Route path={CustomRoutes.createMatch} element={<ProtectedRoute><TrainingForm isMatch={true} /></ProtectedRoute>} />
                    <Route path={CustomRoutes.punishments} element={<ProtectedRoute><PunishmentPage /></ProtectedRoute>} />
                    <Route path={CustomRoutes.paymentHistory} element={<ProtectedRoute><PaymentHistory /></ProtectedRoute>} />
                    <Route path={CustomRoutes.settings} element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
                    <Route path={CustomRoutes.impressum} element={<Impressum />} />
                    <Route path={CustomRoutes.gameLineup + "/:id"} element={<GameLineup/>}/>
                    <Route path={CustomRoutes.trainingLineup + "/:id"} element={<TrainingLineup/>}/>
                    <Route path={CustomRoutes.login} element={<LoginForm />} />
                    <Route path={CustomRoutes.logout} element={<Logout />} />
                    <Route path={CustomRoutes.privacyPolicy} element={<PrivacyPolicy />} />
                    <Route path={CustomRoutes.register} element={<RegisterForm />} />
                    <Route path={CustomRoutes.confirmAccount} element={<ConfirmAccountForm />} />
                    <Route path={CustomRoutes.statistic} element={<AttendanceStatistic />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </div>

            <Footer />
        </div>
    );
};


const NoMatch = () => {
    return <p>There's nothing here: 404!</p>;
};

export default App;