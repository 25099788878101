import React from "react";
import { AttendanceModel, AttendanceStatus } from "./AttendanceModel";
import AttendanceButtons from "./AttendanceButtons";
import "../../../../static/css/appointment/training/attendance/assignAttendance.css";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";

interface AssignAttendanceProps {
    attendance: AttendanceModel[];
    onAttendanceChange: (memberId: string, newStatus: AttendanceStatus) => void;
}

const AssignAttendance: React.FC<AssignAttendanceProps> = ({ attendance, onAttendanceChange }) => {
    const groupAttendanceByStatus = (attendance: AttendanceModel[]) => ({
        [AttendanceStatus.YES]: attendance.filter((a) => a.status === AttendanceStatus.YES),
        [AttendanceStatus.MAYBE]: attendance.filter((a) => a.status === AttendanceStatus.MAYBE),
        [AttendanceStatus.NO]: attendance.filter((a) => a.status === AttendanceStatus.NO),
    });

    const groupedAttendance = groupAttendanceByStatus(attendance);

    const statusLabels = {
        [AttendanceStatus.YES]: "Anwesend (Ja)",
        [AttendanceStatus.MAYBE]: "Vielleicht",
        [AttendanceStatus.NO]: "Abwesend (Nein)",
    };

    return (
        <Row className="container-fluid">
            {Object.entries(groupedAttendance).map(([status, attendees]) => (
                <Col
                    key={status}
                    xs={12}
                    lg={4}
                    aria-label={`Status: ${statusLabels[status as AttendanceStatus]}`}
                >
                    <div className={`attendance-group attendance-${status.toLowerCase()}`}>
                        <h3 className="attendance-title">{statusLabels[status as AttendanceStatus]}</h3>
                        <p className="attendance-count">({attendees.length} Mitglieder)</p>
                        <Row className="" >
                            {attendees.map((attendee) => (
                                <Col xs={12}
                                    key={attendee.player.id}
                                    className="align-items-center justify-content-between mb-2"
                                >
                                    <span className="attendee-name">{attendee.player.name}</span>
                                    <AttendanceButtons
                                        memberId={attendee.player.id}
                                        initialStatus={attendee.status}
                                        onStatusChange={(newStatus) =>
                                            onAttendanceChange(attendee.player.id, newStatus)
                                        }
                                    />
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default AssignAttendance;
