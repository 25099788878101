export class AppointmentModel {
    constructor(
        public id: number,
        public name: string,
        public appointmentType: string,
        public description: string,
        public dateTime: string,
        public playerMustRespond: boolean,
        public meetTime: string | null,
        public startTime: string | null,
        public endTime: string | null,
        public cancellationDeadline: string | null,
        public series: boolean = false,
        public seriesDates: string[] = [],
        public opponent: string,
        public location: string,
        public isHomeGame: boolean
    ) {
        this.id = id;
        this.name = name;
        this.appointmentType = appointmentType;
        this.description = description;
        this.dateTime = dateTime;
        this.playerMustRespond = playerMustRespond;
        this.meetTime = meetTime;
        this.startTime = startTime;
        this.endTime = endTime;
        this.cancellationDeadline = cancellationDeadline;
        this.series = series;
        this.seriesDates = seriesDates;
        this.opponent = opponent;
        this.location = location;
        this.isHomeGame = isHomeGame;
    }
}
