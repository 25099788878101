import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// Importiere deine Übersetzungsdateien
import translationDe from './i18n/translationDE.json';
import translationEn from './i18n/translationEN.json';

i18n
    .use(initReactI18next) // Pass the i18n instance to react-i18next.
    .init({
        resources: {
            de: {
                translation: translationDe, // Deutsche Übersetzungen
            },
            en: {
                translation: translationEn, // Englische Übersetzungen
            },
        },
        lng: 'de', // Standard-Sprache
        fallbackLng: 'en', // Wenn keine Übersetzung gefunden wird, falle auf Englisch zurück
        interpolation: {
            escapeValue: false, // React übernimmt das Escaping
        },
    });

export default i18n;
